var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',[_c('BCard',{attrs:{"title":"Ações"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-2",attrs:{"block":"","variant":"success"},on:{"click":_vm.openModalAction}},[_vm._v("+ Criar nova tarefa")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold text-primary",attrs:{"for":"category"}},[_vm._v("Ir para data:")]),_c('b-form-input',{attrs:{"id":"transactionDate","type":"date"},on:{"change":_vm.updateSchedulerWeek},model:{value:(_vm.start_week_day),callback:function ($$v) {_vm.start_week_day=$$v},expression:"start_week_day"}})],1)],1)],1)],1)],1),_c('BCard',[_c('div',{staticClass:"calendar-wrapper"},[_c('vue-scheduler',{key:_vm.refresh,ref:"scheduler",staticClass:"custom-scheduler",attrs:{"locale":"pt-br","min-date":null,"max-date":null,"labels":{
          today: 'Hoje',
          back: 'Anterior',
          next: 'Seguinte',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          all_day: 'Todos os dias'
        },"time-range":[0, 23],"available-views":[],"active-date":_vm.schedulerInitialDate,"initial-view":"week","showTodayButton":true,"events":_vm.events,"event-display":_vm.eventDisplay,"disableDialog":""},on:{"month-changed":_vm.monthChanged,"week-changed":_vm.weekChanged,"day-changed":_vm.dayChanged,"view-changed":_vm.viewChanged,"day-clicked":_vm.dayClicked,"time-clicked":_vm.timeClicked,"event-clicked":_vm.eventClicked,"event-created":_vm.eventCreated}})],1),_c('Modal',{attrs:{"pre_time":_vm.preset_data,"open":_vm.modalOpen},on:{"closeModal":_vm.closeModal}}),_c('ModalUpdate',{attrs:{"_item":_vm.eventSelect,"open":_vm.modalUpdate},on:{"closeModal":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }